import _ from 'lodash';
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

const originalSubCategories = {
    'RESIDENTIAL': [
        { value: 'KITCHEN', labelId: 'categories.kitchen' },
        { value: 'BATHROOM', labelId: 'categories.bathroom' },
        { value: 'CHILDRENS_ROOM', labelId: 'categories.childrenRoom' },
        { value: 'LIVING_ROOM', labelId: 'categories.livingRoom' },
        { value: 'BEDROOM', labelId: 'categories.bedroom' },
        { value: 'STUDY', labelId: 'categories.study' },
        { value: 'HALL', labelId: 'categories.hall' }
    ],
    'BUSINESS': [
        { value: 'SHOP', labelId: 'categories.shop' },
        { value: 'OFFICE', labelId: 'categories.office' }
        //{ value: 'SANITATION', labelId: 'categories.sanitation' }
    ],
    'HOSPITALITY_FACILITIES': [
        { value: 'ROOMS_APARTMANTS', labelId: 'categories.apartments' },
        { value: 'CATERING_FACILITIES', labelId: 'categories.catering' }
    ],
    'SACRAL': []
};

export default props => {
    const intl = useIntl();
    const { filters, setFilters } = { ...props };
    const activateFilter = (category) => {
        setFilters({
            ...filters,
            subCategories: [category]
        });
    };

    const categories = _.map(originalSubCategories[filters.category], c => ({ ...c, label: intl.formatMessage({ id: c.labelId }) }));

    return (
        <div className="portfolio-filters">
            <div className="category-filter">
                {
                    _.map(categories, category => {
                        return (
                            <div
                                className={_.includes(filters.subCategories, category.value) ? 'pill active' : 'pill'}
                                onClick={() => activateFilter(category.value)}
                            >
                                <div className="text">{intl.formatMessage({ id: category.label })}</div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};
