import React from 'react'
import { Link } from 'gatsby-plugin-intl';

export default (props) => (
    <div className="portfolio-container-v1" id={props.id + ""}>
        <Link to={"/project?id=" + props.id}>
            <div className="portfolio-card">
                <img id={props.id + ""} className="cover" src={'https://nanonet.hr/projects/' + props.id + '/cover.jpg?id=' + props.id}/>
                <div className="details">
                    <div className="text">{props.title}</div>
                </div>
            </div>
        </Link>
    </div>
);
