import { useIntl } from 'gatsby-plugin-intl';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import Header from '../components/header';
import Footer from '../components/footer';
import PortfolioCard from '../components/card/portfolio-card';
import PortfolioFilters from '../components/portfolio-filters';

import residential from '../icons/stambeni_prostori.svg';
import sacral from '../icons/sakralni_prostori.svg';
import business from '../icons/poslovni_prostori.svg';
import hotelsApartments from '../icons/hoteli.svg';
import favicon from '../icons/favicon.ico';
import { Helmet } from "react-helmet";

const subCategoriesDefault = {
    'RESIDENTIAL': ['KITCHEN'],
    'BUSINESS': ['SHOP'],
    'HOSPITALITY_FACILITIES': ['ROOMS_APARTMANTS'],
    'SACRAL': []
};

const getSelectedFilters = (query) => {

    if (query) {
        const parsedQuery = queryString.parse(query);
        const { category, subcategory } = parsedQuery;

        return {
            category,
            subCategories: [subcategory]
        };
    }

    return {
        category: 'RESIDENTIAL',
        subCategories: ['KITCHEN']
    };
};

export default (props) => {
    const intl = useIntl();
    const lang = props.pathContext.language;

    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
            setScrolling(e.target.documentElement.scrollTop > 0);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    const location = useLocation();
    const defaultFilters = getSelectedFilters(location.search);

    const [filters, setFilters] = useState(defaultFilters);
    const [projects, setProjects] = useState([]);

    const fetchProjects = (filters, setProjects) => {
        fetch('https://nanonet.hr/api/projects.php?lang=hr', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                page: 0,
                limit: 100,
                categories: [filters.category],
                subcategories: filters.subCategories
            })
        })
            .then(response => response.json())
            .then(resultData => {
                setProjects(_.orderBy(resultData, 'title'));
            });
    };

    useEffect(() => fetchProjects(filters, setProjects), []);

    const changeQuery = (category, subcategory) => {
        if (!window.history.pushState) {
            return;
        }
        const path = window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            (category ?  ('?category=' + category) : '') +
            (subcategory ? ('&subcategory=' + subcategory) : '');
        window.history.pushState({ path },'', path);
    };

    const onSelectCategory = (category) => {
        const newFilters = {
            category,
            subCategories: subCategoriesDefault[category]
        };
        setFilters(newFilters);
        fetchProjects(newFilters, setProjects);

        changeQuery(category, subCategoriesDefault[category][0]);
    };

    return (
        <div>
            <Helmet>
                <meta property="og:image" content="https://nanonet.hr/cover.jpg"/>
                <meta property="og:image:secure_url" content="https://nanonet.hr/cover.jpg"/>
                <title>{intl.formatMessage({ id: "header.projects" })}</title>
                <link rel="icon" href={favicon} />
            </Helmet>
            <Header lang={lang}/>
            <div className="subheader">
                <div className="tabs">
                    <button
                        className={filters.category === 'RESIDENTIAL' ? 'tab active' : 'tab'}
                        onClick={() => onSelectCategory('RESIDENTIAL')}
                    >
                        <img alt="" src={residential} style={{display: scrolling ? 'none' : 'initial'}} />
                        <p className="text">{intl.formatMessage({ id: 'categories.residential' })}</p>
                    </button>
                    <button
                        className={filters.category === 'BUSINESS' ? 'tab active' : 'tab'}
                        onClick={() => onSelectCategory('BUSINESS')}
                    >
                        <img alt="" src={business} style={{display: scrolling ? 'none' : 'initial'}} />
                        <p className="text">{intl.formatMessage({ id: 'categories.business' })}</p>
                    </button>
                    <button
                        className={filters.category === 'HOSPITALITY_FACILITIES' ? 'tab active' : 'tab'}
                        onClick={() => onSelectCategory('HOSPITALITY_FACILITIES')}
                    >
                        <img alt="" src={hotelsApartments} style={{display: scrolling ? 'none' : 'initial'}} />
                        <p className="text">{intl.formatMessage({ id: 'categories.hospitality' })}</p>
                    </button>
                    <button
                        className={filters.category === 'SACRAL' ? 'tab active' : 'tab'}
                        onClick={() => onSelectCategory('SACRAL')}
                    >
                        <img alt="" src={sacral} style={{display: scrolling ? 'none' : 'initial'}}/>
                        <p className="text">{intl.formatMessage({ id: 'categories.sacral' })}</p>
                    </button>
                </div>
                <div className="portfolio">
                    <PortfolioFilters lang={lang} filters={filters} setFilters={(filters) => {
                        setFilters(filters);
                        fetchProjects(filters, setProjects);
                        changeQuery(filters.category, filters.subCategories[0]);
                    }}/>
                </div>
            </div>
            <div className="portfolio">

                <div className="projects">
                    {
                        _.map(projects, project => <PortfolioCard {...project}/>)
                    }
                </div>
            </div>
            <Footer/>
        </div>
    );
};
